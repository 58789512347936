/* ==========================================================================           
 *
 *    DOCUMENTS
 *
 * ========================================================================== */

 .category-text {
	background: #FFF4DF;
	color: #FF9D00;
}

#createButton {
	position: absolute;
	right: 1rem;
}


