/* ==========================================================================           
 *
 *    PROMOCODE
 *
 * ========================================================================== */

 .promocode-field {
	padding: 0.55rem 1rem!important;
	margin-right: 80px;
 }

 .payment-lifetime { 
	background: rgba(0,188,126,.1);
	color: #00bc7e;
}

#total_discount {
	color: #00bc7e;
}


	  


